:root, [data-bs-theme=light] {
  --bs-primary: #e89644  !important;
  --bs-primary-rgb: 232,150,68 !important;
  --primary-color : #e89644 !important;
  --bs-card-bg : black !important;
}
.App {
  text-align: center !important;   
}

.card {
  background-color: #282c34 !important;
  color: white !important;
}

.App-logo {
  height: 40vmin !important;
  pointer-events: none !important;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear !important;
  }
}


.App-header {
  background-color: #282c34 !important;
  min-height: 100vh !important;
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  justify-content: center !important;
  font-size: calc(10px + 2vmin) !important;
  color: white !important;
}


.link-d
{
  color: #e45c5c !important;
  
  text-decoration: none !important;
}

.bg-dark
{
  
  --bs-primary: #e89644 !important;
  background-color: #3C3A38 !important;
}
.App-link {
  color: #61dafb !important;
}

#copyright {
  color: #E89644 !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg) !important;
  }
  to {
    transform: rotate(360deg) !important;
  }
}

.btn-success {
  color: #fff !important;
  background-color: #e89644 !important;
  border-color: #e89644 !important;
}

a{
  text-decoration: none !important;
}