
.btn-primary {
  --bs-btn-color: #000000 !important;
  --bs-btn-bg: #e89644 !important;
  --bs-btn-border-color: #e89644 !important;
  --bs-btn-hover-color: #000000 !important;
  --bs-btn-hover-bg: #EBA660 !important;
  --bs-btn-hover-border-color: #EAA157 !important;
  --bs-btn-focus-shadow-rgb: 35,23,10 !important;
  --bs-btn-active-color: #000000 !important;
  --bs-btn-active-bg: #EDAB69 !important;
  --bs-btn-active-border-color: #EAA157 !important;
  --bs-btn-disabled-color: #000000 !important;
  --bs-btn-disabled-bg: #e89644 !important;
  --bs-btn-disabled-border-color: #e89644 !important;
}

.btn-outline-primary {
  --bs-btn-color: #e89644 !important;
  --bs-btn-border-color: #e89644 !important;
  --bs-btn-focus-shadow-rgb: 232,150,68 !important;
  --bs-btn-hover-color: #000000 !important;
  --bs-btn-hover-bg: #e89644 !important;
  --bs-btn-hover-border-color: #e89644 !important;
  --bs-btn-active-color: #000000 !important;
  --bs-btn-active-bg: #e89644 !important;
  --bs-btn-active-border-color: #e89644 !important;
  --bs-btn-disabled-color: #e89644 !important;
  --bs-btn-disabled-bg: transparent !important;
  --bs-btn-disabled-border-color: #e89644 !important;
}


.bg-white-300 {
  background: rgba(255, 255, 255, 0.3)   !important;
}

.rounded-bottom-left {
  border-radius: 0 !important;
  border-bottom-left-radius: .25rem  !important;
}

.rounded-bottom-right {
  border-radius: 0 !important;
  border-bottom-right-radius: .25rem  !important;
}

.bs-icon {
  --bs-icon-size: .75rem !important;
  display: flex !important;
  flex-shrink: 0 !important;
  justify-content: center !important;
  align-items: center !important;
  font-size: var(--bs-icon-size) !important;
  width: calc(var(--bs-icon-size) * 2) !important;
  height: calc(var(--bs-icon-size) * 2) !important;
  color: var(--primary-color) !important;
}

.bs-icon-xs {
  --bs-icon-size: 1rem !important;
  width: calc(var(--bs-icon-size) * 1.5) !important;
  height: calc(var(--bs-icon-size) * 1.5) !important;
}

.bs-icon-sm {
  --bs-icon-size: 1rem !important;
}

.bs-icon-md {
  --bs-icon-size: 1.5rem !important;
}

.bs-icon-lg {
  --bs-icon-size: 2rem !important;
}

.bs-icon-xl {
  --bs-icon-size: 2.5rem !important;
}

.bs-icon.bs-icon-primary {
  color: var(--bs-white)   !important;
  background: var(--primary-color)   !important;
}

.bs-icon.bs-icon-primary-light {
  color: var(--primary-color)  !important;
  background: rgba(var(--bs-primary-rgb), .2)   !important;
}

.bs-icon.bs-icon-semi-white {
  color: var(--primary-color)  !important;
  background: rgba(255, 255, 255, .5)  !important;
}

.bs-icon.bs-icon-rounded {
  border-radius: .5rem !important;
}

.bs-icon.bs-icon-circle {
  border-radius: 50% !important;
}

